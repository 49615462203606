.upload_document {
    padding-bottom: 11rem;
}

.upload_document .background_bleu_gradiant {

    background-color: #fff;
    background: linear-gradient(45deg, #0061d1, 50%, #0061d1, #66cde2);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    padding-bottom: 6rem;
    
}

.upload_document .background_bleu_gradiant .single_div_for_flex{
    padding-top: 2.8rem;/*
    border: solid;
    border-color: green;*/
    align-content: center;
    width: 90%;
    padding-left: 3.5rem;
    padding-right: 3.5rem;
}
.upload_document .background_bleu_gradiant .single_div_for_flex .bienvenue_user {
    color: #66cde2;
    font-size: 1.6rem;
    padding-bottom: 2rem;
}
.upload_document .background_bleu_gradiant .single_div_for_flex .credit_div {
    
    padding-bottom: 3rem;
}
.upload_document .background_bleu_gradiant .single_div_for_flex .credit_div span {
    font-size: 1.3rem;
    background-color: #66cde2;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.upload_document .background_bleu_gradiant .single_div_for_flex .analyse_doc {
    color:white;
    font-weight: bold;
    padding-bottom: 2rem;
}

.upload_document .background_bleu_gradiant .single_div_for_flex .choose_doc_type_text {
    color: white;
    font-size: 1.2rem;
}

.upload_document .background-white-and-blue { 
    height: 11rem;
    /*background: linear-gradient(to bottom, #0061d1 50%, #fff 50%);*/
    position: relative;
    top: -5.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom:  -5.5rem;
    /*align-items: center;*/
}

.upload_document .background-white-and-blue .type_document_choice  .connected_img {
    
    position: relative;
    width: 1.6rem !important;
    right: 0rem;
    bottom: 2.3rem;
    
}


.upload_document .background-white-and-blue .type_document_choice {
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.upload_document .background-white-and-blue .type_document_choice > div{ /*direct children*/
    width: 7rem;
    height: 7rem;
    display: flex ;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: white;
    border-radius: 2rem;
    
}

.upload_document .background-white-and-blue .type_document_choice > div p {
    color: #0061d1;
    font-weight: bold;
    padding-top: 0.2rem;
}

.upload_document .background-white-and-blue .type_document_choice :nth-child(1) img{

    width: 80%;
    height: 70%;
}
.upload_document .background-white-and-blue .type_document_choice :nth-child(2) img{

    width: 80%;
    height: 70%;
}

.upload_document .background-white-and-blue .type_document_choice :nth-child(3) img{

    width: 80%;
    height: 70%;
}

.upload_document .background-white-and-blue .type_document_choice img {

    padding-top: 1rem;
}

.upload_document .background-white-and-blue .type_document_choice :nth-child(2){
    width: 7rem;
    height: 7rem;
}

.upload_document .background-white-and-blue .type_document_choice :nth-child(3){
    width: 7rem;
    height: 7rem;
}

.upload_document .text_container {
    color: #0061d1;
    text-align: center;
    font-size: 1.4rem;
    padding-top: 3rem;
    padding-bottom: 2.2rem;
    display: flex;
    flex-direction: column;

}

.upload_document .container {
    background-color: #e3f0ff;
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    padding-left: 0px;
    margin-left: 0px;
    margin-right: 0px !important;
    border-radius: 2.4rem;
    width: 100%;
    max-width: none !important; /* Des composants importés modifient la max-width en fonction de la taille de l'écran. */
    text-align: center;
    padding-top: 2rem;
}

.upload_document .container h1{
    color: #0061d1;
    padding-bottom: 1.3rem;
}

.container .div_select_field_and_button {
    display: flex;
    flex-direction: column;

    align-items: center;
    padding-top: 4rem;
}
