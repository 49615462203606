.facturation {
    padding-top: 2.2rem;
    
}

.facturation h5 {
    color: #0061d1 !important;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
}

.facturation .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg_bleu_fonce.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.facturation .background_div h2 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.facturation .backgound_bleu_gradiant {
    background: linear-gradient(to right top, #4a9cea 70%,#0262d2);
    padding: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}

.facturation .backgound_bleu_gradiant .background_white{
    background: white;
    width: 100%;
}

.facturation .backgound_bleu_gradiant .date_selector {

    width: 100%;

}

.facturation .backgound_bleu_gradiant .background_white .achat_individuel {

    width: 100%;
    padding: 3rem;
    display: grid;
    grid-template-columns: repeat(2,2fr);
    align-items: center;

}

.facturation .backgound_bleu_gradiant .background_white .achat_individuel .date{
    width: 7rem;
    height: 7rem;
}

.facturation .backgound_bleu_gradiant .background_white .achat_individuel .formule{
    width: 7rem;
    height: 7rem;
}

.facturation .backgound_bleu_gradiant .background_white .achat_individuel .montant{
    width: 7rem;
    height: 7rem;
}

@media (min-width: 1400px)
{
    .facturation .background_div {
        margin-right: 34%;
        margin-left: 34%;
        background-position-x: calc(95%);
    }

    .facturation h5{
        margin-left: 8%;
    }
    
    .facturation .backgound_bleu_gradiant {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10rem;
    }

    .facturation .backgound_bleu_gradiant .date_selector{
        width: 40%;
    }

    .facturation .backgound_bleu_gradiant .background_white{
        width: 40%;
    }

    .facturation .backgound_bleu_gradiant .background_white .achat_individuel {

        padding: 3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;

    }



}