.test-qokka-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem 4rem 2rem;
    text-align: center;
    font-size: 21px;
    background-color: #fff;
}

.test-qokka-block .subtitle {
    color: #606060;
}

.test-qokka-block .medium {
    margin-top: 2rem;
    font-size: 30px;
}

.test-qokka-block .item .medium {
    font-size: 21px;
}

.test-qokka-block hr {
    width: 100px;
    max-width: 50%;
    border: #66cde2 solid 5px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 5px;
    margin-bottom: 3rem;
}

.test-qokka-block .circle-number {
    padding: 10px;
    width: 50px;
    height: 50px;
    font-size: 28px;
    line-height: 32px;
    border-radius: 30px;
    font-weight: bold;
    background-color: #4a9cea;
    color: #fff;
    box-shadow: none;
}

.test-qokka-block .description {
    margin-top: 1rem;
    text-align: left;
}

.test-qokka-block .logo-qokka-color {
    max-width: 100%;
    width: 195px;
}

.test-qokka-block .horizontal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.test-qokka-block .horizontal-container .item {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.test-qokka-block .item p {
    margin-bottom: 0;
}

@media (min-width: 900px) {
    .test-qokka-block {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    .test-qokka-block .medium {
        font-size: 36px;
        max-width: 60%;
    }

    .test-qokka-block .logo-qokka-color {
        width: 400px;
    }

    .test-qokka-block .horizontal-container {
        flex-direction: row;
        justify-content: center;
        gap: 0;
    }

    .test-qokka-block .horizontal-container .item {
        width: 20%;
    }

    #test-btn {
        position: relative;
        z-index: 1001;
        font-size: 26px !important;
    }
}

@media (min-width: 1400px) {
    .test-qokka-block {
        padding: 6rem 10% 6rem 10%;
    }
}