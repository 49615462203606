
.upload_component #text_info_user_drop_files{
}

.upload_component #thumbnails_div_container {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    padding-bottom: 1.7rem;
}
.upload_component #container_result_analysis {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload_component #container_result_analysis img {
    width: 3.5rem;
    height: 3.5rem;
}
.upload_component #h3_message_result_after_analysis {
    padding-top: 2rem;
    color: #0061d1;
    width: 70%;
}
.upload_component #thumbnails_div_container > div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.upload_component #thumbnails_div_container .background_thumbnail {
    align-content: center;

    width: 43%;
    background-color: white;
    padding: 1.4rem;
    border-radius: 1rem;
}
.upload_component #thumbnails_div_container .background_thumbnail img
{   
    width: 100%;
    border-radius: 0.5rem;

}

.upload_component .div_select_field_and_button .label_container {
}

@media (min-width: 800px) {

    .upload_component .div_select_field_and_button .label_container {
    }

}

@media (min-width: 1800px) {
    .upload_component .div_select_field_and_button #div_uploaded_file {
        
    }

    .upload_component #text_info_user_drop_files{
    
    }
}