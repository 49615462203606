
.mdpReinitialisedOK{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mdpReinitialisedOK .main_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.mdpReinitialisedOK .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100%);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}
.mdpReinitialisedOK .merci_div {

    background-color: #d6ecf1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 5rem;
    padding-right: 5rem;
    padding-left: 5rem;
    padding-bottom: 2rem;
    border-radius: 3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

#merci{
    font-style:normal;
    font-weight: 100;
    font-size:1.2em;
    color: rgb(23, 121, 179);

}

#image_letter{
    margin:-240px -210px;
    width: 400px;
    height: 500px;

}

#connect_button {
    background-color: #9af7d0;
    padding: 10px 20px;
    border-radius: 100px;
    text-decoration: #000;
    color: #000;
}


@media (min-width: 1400px)
{



}