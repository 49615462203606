
.resiliation {
    padding-top: 2.2rem;

}

.resiliation p.hello {
    color: #0061d1 !important;
    margin: 0 0rem;
    font-size: 24px !important;
    padding: 0 2rem 0.5rem 2rem;
}

.resiliation .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.resiliation .background_div h1 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.resiliation .current_package_details {
    background: linear-gradient(#0061d1, #0061d1 80%, #4a9cea);

    border-radius: 3rem;
    padding: 2.5rem 1rem 1.3rem 1rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;


}

.resiliation .current_package_text,
.resiliation .current_package {
    color: #fff;
    font-size: 30px !important;
}

.resiliation .current_package {
    margin: 0;
    padding-bottom: 0rem;
    text-transform: uppercase;
}

.resiliation .used_credit,
.resiliation .extra_credit {
    font-size: 19px !important;
    font-family: 'RubikRegular' !important;
    font-weight: bold;
    padding: 0rem 1rem;
    margin: 0rem 1rem;
    text-transform: uppercase;
}

.resiliation .used_credit {
    margin: 1rem 1rem;
}

.resiliation .extra_credit {
    color: #fff;
    background-color: #0061d1;
    border: 1px solid #fff;
}

.resiliation .current_package_details .ul_div_container ul {
    list-style: none;
    padding-inline-start: 0 !important;
    color: white;
    text-align: left;
    margin-top: 2rem;
}

.resiliation .current_package_details .ul_div_container ul li {
    background: url(../icons/list-circle_different_color.svg) no-repeat left top;
    background-position-y: 7px !important;
    background-size: 0.75rem;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    font-size: 21px;
}

.resiliation .ul_dates_account {
    margin-left: 1rem;
}

.resiliation .ul_dates_account li {
    font-family: 'RubikLight' !important;
    font-size: 18px !important;
}

.resiliation .btn {
    font-size: 18px !important;
    width: auto !important;
    padding: 1rem 2rem !important;
}

.resiliation .spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.resiliation .subtitle {
    font-size: 30px !important;
    margin-bottom: 2rem;
    width: 100%;
}

.resiliation .user-blue,
.resiliation .blue {
    color: #0061d1 !important;
}

.resiliation .under_div_stats {
    margin-top: 6rem !important;
    padding: 4rem 2rem !important;
    background-color: #e3f0ff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resiliation .conditions_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.resiliation .conditions_container ul {
    margin: 0;
    list-style: none;
    padding-inline-start: 0.2rem !important;
}

.resiliation .conditions_container ul li {
    background: url(../icons/15Resiliation/icon_bleu_utils.svg) no-repeat left top;
    background-size: 25px;
    background-position-y: calc(2rem + 5px);
    padding-left: 2rem;
    padding-top: 2.0rem;
}

.resiliation .cancel_container {
    padding: 2rem;
    text-align: center;
}

@media (min-width: 900px)
{    
    .resiliation .cancel_container {
        padding: 2rem 20%;
    }

    .resiliation .background_div {
        margin: auto;
        background-position-x: calc(50% + 210px);
        background-size: 240px;
        align-items: center;
    }

    .resiliation .background_div h1 {
        font-size: 60px !important;
        max-width: 400px;
    }

    .resiliation .current_package_text,
    .resiliation .current_package {
        font-size: 33px !important;
    }

    .resiliation .current_package_details {
        max-width: 600px;
        display: flex;
        margin: auto;
    }

    .resiliation p.hello {
        padding-left: 20%;
        padding-right: 20%;
    }

    .resiliation .used_credit,
    .resiliation .extra_credit {
        font-size: 21px !important;
        padding: 0.25rem 2rem;
    }

    .resiliation .ul_dates_account li,
    .resiliation .btn {
        font-size: 20px !important;
        margin-top: 1.5rem !important;
    }

    .resiliation .btn {
        padding: 1rem 3rem !important;
        border-radius: 50px !important;
    }

    .resiliation p.hello {
        font-size: 27px !important;
    }

    .resiliation .subtitle {
        width: auto;
    }

    .conditions_container {
        flex-direction: row;
    }

    .resiliation .under_div_stats {
        padding-left: 20% !important;
        padding-right: 20% !important;
    }

    .resiliation .conditions_container {
        flex-direction: row;
        gap: 2rem;
        align-items: flex-start;
    }


    .resiliation .conditions_container ul {
        width: 50%;
    }
}