
.correct-coordonnees .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
    margin-top: 4rem;
}

.correct-coordonnees .background_div h2 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.correct-coordonnees .spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.correct-coordonnees .utils_div {

    background-color: #e3f0ff !important;
    border-radius: 20px !important;
    padding: 4rem 2rem 4rem 2rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 4rem;
    
}

.correct-coordonnees .utils_div > form{

    display: flex;
    flex-direction: column;
    align-items: center;
}

.correct-coordonnees h1 {
    font-size: 45px !important;
    font-weight: bold !important;
}

.correct-coordonnees h2 {
    font-size: 30px !important;
    color: #0061d1 !important;
    text-align: center;
}

.correct-coordonnees .form {
    width: 100%;
    padding-top: 4rem !important;
    padding-left: 0px !important;
    padding-right: 0px !important;}

.correct-coordonnees form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.correct-coordonnees .modify {
    margin-top: 4rem !important;
    font-size: 24px !important;
}

.correct-coordonnees .MuiFormControlLabel-root {
    width: 100%;
}

.correct-coordonnees .cancel {
    font-size: 16px !important;
    color: #0061d1 !important;
    text-decoration: underline;
}

@media (min-width: 900px){

    .correct-coordonnees h1 {
        font-size: 60px !important;
        width: 100%;
    }

    .correct-coordonnees h2 {
        font-size: 33px !important;
    }

    .correct-coordonnees .background_div {
        width: 100%;
        background-position-x: calc(50% + 220px);
        background-size: 250px;
        
    }

    .correct-coordonnees .utils_div {
        width: calc(100% - 4rem);
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .correct-coordonnees {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .correct-coordonnees .modify {
        font-size: 26px !important;
    }

    .correct-coordonnees .cancel {
        font-size: 21px !important;
    }
}

@media (min-width: 1400px) {
    .correct-coordonnees .utils_div {
        width: calc(100% - 40%);
        margin-left: 20% !important;
        margin-right: 20% !important;
    }
}