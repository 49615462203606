.cgv .cgvDiv {

    width: 70%;
    background-color: #e3f0ff; 
    border-radius: 3rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column; 

}
.cgv .ul1 {
    margin-left: 1.5rem;
    text-align: left;
}
.cgv .ul1bis {
    margin-left: 1.5rem;
    text-align: left;
}
.cgv .ul2 {
    margin-left: 1.5rem;
    text-align: left;
}

@media (min-width: 900px) {

    .cgv .cgvDiv {
        padding-left: 5rem;
        padding-right: 5rem;
    }
    .cgv .ul2 {
        margin-left: 4rem;
    }
    .cgv .ul1 {
        margin-left: 4rem;
    }
    .cgv .ul1bis {
        margin-left: 4rem;
        text-align: left;
    }
}