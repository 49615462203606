.message_from_server{
    background-color: chartreuse;
}

.ContactNoConnectComponent .info-block .changing-background{
    background: url(../icons/Qbg.svg) no-repeat left top;
    background-position-x: calc(100% + 35px);
    background-position-y: calc(50%);
    background-size: 160px;
    height: 15rem;
    padding-top: 5rem;
    text-align: center;
}

.ContactNoConnectComponent .main_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.ContactNoConnectComponent .info-block{
    padding-bottom: 2.5rem;
    
}

.ContactNoConnectComponent .info-block .utils1{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.ContactNoConnectComponent .info-block .text-align-left{
    text-align: left;
}

.ContactNoConnectComponent .main_container form {
    
    padding-top: 7rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    background-color: #0061d1;
    text-align: left;
    
}

.ContactNoConnectComponent .main_container form label{
    color: white !important
}
.ContactNoConnectComponent .main_container form :nth-child(5) div{
    padding-bottom: 16rem  !important 
}
.ContactNoConnectComponent .main_container .div_lettre_image_et_text{
    padding-top: 8rem;
    padding-bottom: 3rem;
}
.ContactNoConnectComponent .main_container .div_lettre_image_et_text .container_image_lettre{
    padding-bottom: 2rem;
}
.ContactNoConnectComponent .main_container .div_lettre_image_et_text img{
    height: 4rem;
    width: 4rem;
}
.ContactNoConnectComponent .main_container .div_lettre_image_et_text p{
    margin-bottom: 0rem;
}

@media (min-width: 1400px) {
    .ContactNoConnectComponent .info-block{
        padding-top: 1rem;
        
        display: flex;
        flex-direction: column;
        
        align-items: center;
        justify-content: normal;
    }
    .ContactNoConnectComponent .info-block .changing-background{
        background: url(../icons/Qokka_and_women_with_heart.svg) no-repeat left top;
        background-position-y: calc(80%) !important;
        background-size: 100%;
        padding-bottom: 2rem;
        height: 30rem;
        width: 45rem;
        padding-top: 5rem;
        text-align: center;
        font: 'RubikRegular' !important;
    }

    .ContactNoConnectComponent .info-block .changing-background h1{
        width: 60%;

        font-weight: bold;

    }

    .ContactNoConnectComponent .info-block .utils1{
        padding-top: 1rem;
        width: 45rem !important;
        font-size: 1.5rem;
        font-family: "RubikMedium" !important;
    }

    .ContactNoConnectComponent .main_container form {
        padding-left: 31%;
        padding-right: 31%;
    }   
}