.who-tile {
    margin: 2rem;
    padding: 2rem;
    background-color: #e3f0ff; 
    border-radius: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    text-align: left;
}

.who-tile .title {
    
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.who-tile .title img {
    max-width: 100%;
    width: 60px;
}
.who-tile .title .logoContainer {
    float: left;
    margin: 0 15px 0 0;
    width: 20%;
}

.who-tile h1, h4 {
    color: #66cde2;
}

.who-tile ul {
    list-style: none;
    padding-inline-start: 0 !important;
}

.who-tile ul li {
    background: url(../../icons/list-circle.svg) no-repeat left top;
    background-position-y: 0.6rem;
    background-size: 0.75rem;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    font-size: 21px;
    color: black;
}

@media (min-width: 1400px)
{
.who-tile {
    width: 50%;
}
}