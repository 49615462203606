label {
  display: block;
/*  margin-top: 10px;*/
}

/*
.login-card
{
  max-width: 350px !important;
}

.card-container.card {
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}*/

/*
.form-group-checkbox {
  display: flex;
}

input[type=checkbox] {
  margin-top: 16px;
  margin-right: 5px;
}*/

.MuiButton-root {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
  color: #383e4d !important;
}

.form-title {
  margin-bottom: 2rem
}

.center {
  text-align: center;
}


.promo {
  background: linear-gradient(45deg, #9af7d0, #66cde2);  
  text-align: center;
  font-size: 18px;
}

.promo-shape {
  background-image: url(./icons/promo.svg);
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  padding: 5rem 4rem 0.5rem 4rem !important;
}

.promo p {
  margin-bottom: 0px 1rem !important;
}

.offer-type {
  color: #0061d1;
  text-transform: uppercase;
  font-size: 12px !important;
  font-weight: 800 !important;
  margin-bottom: 0.5rem !important;
}

.promo button {
  background-color: #fff !important;
  padding: 0.5rem 2rem;
  border-radius: 20px / 50%;
  font-weight: 800 !important;
  font-size: 12px !important;
  font-family: 'RubikRegular', sans-serif !important;
  color: #0061d1 !important;
  border: 2px #fff solid !important;
}

.promo button:hover {
  background-color: #cff4f7 !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 10px 2px #cff4f7 !important;
}

.extension {
  height: 30px;
}

.offer-counters-container {
   background-color: #66cde2;
   display: none !important;
   flex-direction: row;
   justify-content: center;
 }

 .offer-counters {
   display: flex;
   flex-direction: row;
 }

 .offer-counter {
   padding: 1rem;
   display: flex;
   flex-direction: column;
 }

 .offer-counter-number {
   font-size: 31px;
   line-height: 25px;
   font-weight: bold;
 }

 .offer-counter-unit {
   line-height: 20px;
 }

footer {
  background-color: #383e4d;
  padding: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
}

footer img {
  width: 170px;
  margin-bottom: 1rem;
}

footer #logo {
  margin-bottom: 2rem;
}

footer #subscribe-button {
  font-size: 14px;
  border-color: #fff !important;
  padding: 0.5rem 2.5rem;
  border-radius: 25px/50%;
  margin: 0 0 2rem 0 !important;
}

footer ul {
  margin: 0 0 2rem 0 !important;
  padding: 0;
  font-size: 14px;
}

footer ul a.MuiButton-root {
  padding: 0 0.5rem !important;
  margin: 0 0 0.5rem 0 !important;
}

footer .MuiButton-root, footer p {
  color: #fff !important;
}

.cardTextContact {
  color: #000000 !important;
}

.cartUrlContact {
  color: #1890ff !important;
}

.cartUrlContact:hover {
  color: #1890ff !important;
}

footer a {
  text-decoration: none;
  color: #fff !important;
}

footer a:hover {
  color: #fff !important;
}


footer .partners .logoFlexDisplayer {
  width: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

}

footer .partners .logoFlexDisplayer img{
  height: 5rem;
  width: 5rem;
}

footer .partners .contactPress{
  padding-left: 0.7rem;
}
footer .partners .socialMedia{
  padding-left: 0.7rem;
  padding-top: 0.5rem
}
footer .partners .flexerSocialMedia{
  display: flex;
  flex-direction: row;
  padding-left: 0.7rem;
}

@media (min-width: 900px) {
  footer {
    padding: 6rem 2rem 1rem 2rem;
    font-size: 16px;
  }
  footer .partners .flexerSocialMedia{
    display: flex;
    flex-direction: row;
    padding-left: 0rem;
  }

  footer .flexerUtilsFooter {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  footer .partners {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  footer .partners .contactPress{
    padding-left: 0rem;
  }
  footer .partners .socialMedia{
    padding-left: 0rem;
  }
  
  footer #subscribe-button {
    font-size: 17px;
    margin-bottom: 3rem!important;
  }

  footer img {
    width: 250px;
  }

  footer #logo {
    margin-bottom: 2rem;
  }

  footer ul a.MuiButton-root {
    margin: 0 0 1rem 0 !important;
  }

  .copyright {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 900px) {
  .offer-counters-container {
    display: flex !important;
  }

  .offer-type{
    font-size: 24px !important;
  }

  .promo button {
    padding: 0.2rem 2rem;
    border-radius: 20px/50%;
    font-size: 16.52px !important;
  }

  .promo p {
    margin: 0px 1rem !important;
  }

  .promo-shape {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .main-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media (min-width: 1400px) {
  footer {
    padding: 6rem 10% 1rem 10%;
  }
}
