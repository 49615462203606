
.offer-choice .text-container {
    padding: 0rem 1rem 2rem 1rem;
    margin-top: 6rem;
    background: url(../icons/O.svg) no-repeat top right;
    background-size: 140px;
    background-position-y: 20px;
}

.offer-choice .text-container h1 {
    font-weight: bold;
    font-size: 45px;
    color: #0061d1;
    text-align: center;
}

.offer-choice-connected .text-container h1 {
    font-size: 40px;
    text-align: left;
    padding-right: 5rem;
    margin-bottom: 4rem;
}

.offer-choice .text-container .subtitle {
    font-size: 24px;
    color: #0061d1;
    text-align: center;
    padding: 2rem 1rem 2rem 1rem;
}

.offer-choice .text-container p {
    font-size: 21px;
}

.offer-choice .spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.offer-choice .offers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 0 2rem;
}

.offer-choice .offer {
    background-color: transparent;
    border: 10px dotted transparent;
    border-radius: 30px;
    min-width: 305px;
}

.offer-choice .offer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 15px 5px #499bea50;
    border-radius: 30px;
    overflow: visible;
    min-width: 285px;
    justify-content: space-between;    
}

.offer-choice .offer .topIcon
{
    width: 30%;
    max-width: 100px;
}

.offer-choice .offer .name {
    font-weight: bold !important;
    font-size: 24px;
    text-transform: uppercase;
    mask-image: url(../icons/product-top-logo.svg) top center;
    margin: 0.5rem 0 1rem 0;
}

.offer-choice .consultations {
    font-weight: bold !important;
    text-transform: uppercase;
    font-size: 19px;
    padding: 0.25rem 2rem;
    margin-bottom: 2rem;
}

.offer-choice .offer .description {
    padding: 0 1rem;
    line-height: 1.8rem;
    text-align: center;
    margin: 0 !important;
}

.offer-choice #description-txt2 {
    margin-bottom: 1rem !important;
}

.offer-choice .offers .btn, .offer-choice .offers .current-subscription {
    font-size: 19px !important;
    padding: 1rem 2rem !important;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: auto;
    line-height: 1.75;
    text-align: center;
}

.offer-choice .offers .current-subscription {
    background-color: #ffe470;
    border: 2px solid #ffe470;
    margin: 9px;
}

.offer-choice .offer .bg-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;
    top: -1px;
    margin-bottom: -1px;
}

.offer-choice .offer .wave {
    display: flex;
    width: 100%;
}

.offer-choice .offer .stroken, .offers .unit {
    font-weight: bold;
    font-size: 23px;
}

.offer-choice .offer .stroken {
    text-decoration: line-through;
}

.offer-choice .offers .price {
    font-weight: bold;
    font-size: 36px;
}

.offer-choice .extra .price {
    color: #fff;
}

.offer-choice .extra .unit {
    color: #757575;
}

.offer-choice .offer .comment, .offer-choice .offer .pricePerConsultation {
    font-size: 14px;    
}

.offer-choice .offers .pricePerConsultation {
    margin-bottom: 1rem !important;
}

.offer-choice .offer .more {
    padding: 0.75rem 1rem 1rem 1rem;
    margin: 0 0 0 0 !important;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    text-decoration: underline !important;
    border-radius: 0;
    transition: background-color 0.25s ease-in;
}

.offer-choice .extras {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem 2rem;
}

.offer-choice .extra {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 15px 5px #499bea50;
    border-radius: 30px;
    overflow: hidden;
    min-width: 285px;
}

.offer-choice .extra .topIcon {
    width: 30%;
    max-width: 100px;
}

.offer-choice .extra .name {
    font-weight: bold !important;
    font-size: 24px;
    text-transform: uppercase;
    mask-image: url(../icons/product-top-logo.svg) top center;
    margin: 0.5rem 0 1rem 0;
    color: #fff !important;
}

.offer-choice .extra .consultations {
    background-color: #fff;
}

.offer-choice .extra .reserved {
    font-weight: bold !important;
    font-size: 19px;
    padding: 0.25rem 2rem;
    margin-bottom: 2rem;
    background-color: #fff;
}

.offer-choice .extra .description {
    padding: 0 1rem;
    line-height: 1.8rem;
    text-align: center;
    color: #fff;
}

.offer-choice .offer .promoContainer {
    width: 100%;
    min-width: 285px;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    height: 0;
}

.offer-choice .offer .promoBanner {
    background: url(../icons/product-promo.svg) top right;
    background-size: contain;
    background-repeat: no-repeat;
    width: 152px;
    height: 176px;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    top: -11px;
    right: -9.5px;
}

.offer-choice .offer .promoBanner span {
    font-family: 'RubikBlack';
    color: #0061d1;
    font-size: 25px;
    transform: rotate(49deg);
    position: relative;
    top: -0.9rem;
    left: -1.2rem;
}

.offer-choice .block-top, .offer-choice .block-middle, .offer-choice .block-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.offer-choice .padding-wave {
    display: none !important;
}

#packages .promo-shape .note {
    font-size: 14px !important;
}

#packages .offer-counters-container {
    display: flex !important;
}

.offer-choice .test-qokka-block {
    margin-top: 2rem;
}

.offer-choice .text-container2 {
    padding: 1rem;
}

.offer-choice .Mui-error {
    font-size: 21px;
    color: #d32f2f;
    text-align: center;
}

.offer-choice-connected {
    margin-bottom: 1rem;
}

.offer-choice .extra .comment, .offer-choice .extra .pricePerConsultation {
    color: #fff;
}

.current-offer-bubble {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
}

.horizontal-selector {
    display: none;
    padding-left: 2rem;
    padding-right: 2rem;
}

.current-offer-bubble-container, .current-offer-bubble-container2 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.current-offer-bubble-arrow {
    height: 30px;
    width: 60px;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.current-offer-bubble .text {
    color: #fff;
    font-size: 30px;
}

.current-offer-bubble .name {
    font-size: 30px;
    text-transform: uppercase;
}

.current-offer-bubble .consultations {
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;
}

.current-offer-bubble-extra-padding {
    display: none;
}

@media (max-width: 899px)
{
    #packages .promo-shape .offer-type {
            font-size: 18px !important;
    }

    #packages .promo-shape {
            padding: 3rem 3rem 1rem 3rem !important;
    }

    #packages .offer-counter {
        padding: 1rem 0.5rem !important;
    }

    #packages .offer-counter-number {
        font-size: 21px !important;
    }

    #packages .offer-counter-unit {
        font-size: 12px !important;
    }
}

@media (min-width: 900px)
{
    .horizontal-selector {
        display: flex;
        flex-direction: row;        
        justify-content: space-around;
    }

    .current-offer-bubble-container2 {
        min-width: calc(50% - 2rem);
        clip-path: polygon(40px 0, calc(100% - 40px) 0, calc(100% - 40px) 100%, 40px 100%);
    }

    .current-offer-bubble-container {
        display: none;
    }

    .offer-choice .text-container {
        padding-left: 0;
        padding-right: 0;
        margin-left: calc(10% + 6rem);
        margin-right: calc(10% + 6rem);
        background: url(../icons/O_o.svg) no-repeat top right;
        background-size: 225px;
        background-position-y: 0px;
    }

    .offer-choice .text-container h1 {
        text-align: left;
        font-weight: bold;
        font-size: 65px;
        padding-top: 3rem;
        margin-bottom: 2rem;
    }

    .offer-choice .text-container .subtitle {
        font-size: 28px;
        color: initial;
        text-align: center;
        padding: 2rem 1rem 2rem 1rem;
        padding: 0;
        text-align: left;
    }

    .offer-choice .text-container p {
        font-size: 28px;
        color: #383e4d !important;
        margin-bottom: 2rem;
    }

    .offer-choice .description {
        font-size: 21px;
    }

    .offer-choice .name {
        font-size: 28px !important;
    }

    .offer-choice .text-container2 {
        padding: 1rem 10%;
    }

    .offer-choice .Mui-error {
        font-size: 28px !important;
    }

    .offer-choice-connected {
        margin-bottom: 4rem;
    }
}

@media (min-width: 1170px)
{
    .offer-choice .text-container {
        background-size: 400px;
    }

    .offer-choice .text-container h1 {
        padding-right: 200px;
    }

    .offer-choice .text-container .subtitle {
        padding-right: 400px;
    }

    .offer-choice .text-container p {
        padding-right: 100px;
    }
}

@media (min-width: 900px) {
    .offer-choice .offers .btn {
        font-size: 23px !important;
        border-radius: 50px !important;
    }
        
    .offer-choice .offers .current-subscription {
        font-size: 23px !important;
    }

    .offer-choice .offers {
        padding-left: 2rem;
        padding-right: 2rem;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        flex-wrap: wrap !important;
    }
    
    .offer-choice .offer {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: calc(50% - 2rem) !important;
    }

    .offer-choice .extras {
        padding-top: 4rem;
        padding-left: 20%;
        padding-right: 20%;
    }

    .offer-choice .offer .stroken {
        font-size: 24px !important;
    }

    .offer-choice .padding-wave {
        display: flex !important;
    }

    .offer-choice .price {
        font-size: 41px !important;
    }
    
    .offer-choice .offers .comment,
    .offer-choice .offers .pricePerConsultation {
        font-size: 16px;
    }

    .offer-choice .text-container {
        padding-bottom: 6rem;
    }

    .offer-choice .offer .promo-padding {
        margin-bottom: 45px !important;
    }
}

@media (min-width: 1500px) {
    .offer-choice .offers, .horizontal-selector {
        padding-left: 5%;
        padding-right: 5%;
        justify-content: space-between !important;
        flex-wrap: nowrap !important;
    }

    .offer-choice .offer, .offer-choice-connected .extra {
        max-width: calc(33% - 2rem) !important;
    }

    .offer-choice .extras {
        padding-top: 4rem;
        padding-left: 20%;
        padding-right: 20%;
    }

    .offer-choice .extra .promo-padding {
        margin-bottom: 20px !important;
    }
    
    .current-offer-bubble-container2 {
        min-width: calc(33% - 2rem);
    }

    .current-offer-bubble-extra-padding {
        display: flex;
        margin: 10px;
        min-width: calc(33% - 2rem) !important;
    }
}