.confirm_resiliation {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirm_resiliation .background_div {
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.confirm_resiliation h1 {
    font-weight: bold;
    font-size: 45px !important;
    text-align: center;
}

.confirm_resiliation .main_background {

    background-color: #e3f0ff;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 4rem;
    gap: 1rem;
    
}

.confirm_resiliation .spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.confirm_resiliation h2, .confirm_resiliation .subscription {
    margin: 0 !important;
    font-size: 30px !important;
    color: #0061d1;
}

.confirm_resiliation .used_credit {
    font-size: 19px !important;
    font-family: 'RubikRegular' !important;
    font-weight: bold;
    padding: 0rem 1rem;
    margin: 0rem 1rem;
    text-transform: uppercase;
}

.confirm_resiliation .txt, .btn {
    font-size: 24px !important;
    color: #0061d1;

}

@media (min-width: 900px)
{
    .confirm_resiliation .utils_desktop_mode{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: center;
        margin-bottom: 8rem;

    }
    .confirm_resiliation .background_div {

        padding-right: 20%;
        padding-left: 20%;
        background-position-x: calc(95%);
        background-size: 250px !important;
        background-position-x: calc(50% + 210px);
    }

    .confirm_resiliation .main_background {
        padding-left: 2rem;
        padding-right: 2rem;
        margin-left: 20%;
        margin-right: 20%;
        margin-bottom: 2rem;
    }

    .confirm_resiliation h1 {
        max-width: 700px;
        margin: auto;
        font-size: 60px !important;
    }

    .confirm_resiliation h2,
    .confirm_resiliation .subscription {
        font-size: 33px !important;
    }

    .confirm_resiliation .used_credit {
        font-size: 21px !important;
        padding: 0.25rem 2rem;
    }
    
    .confirm_resiliation .btn {
        font-size: 26px !important;
    }
}