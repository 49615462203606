.why-block {
    background: url(../../icons/why_bg_mobile.svg) no-repeat center center;
    background-size: cover !important;
    color: #fff;
    width: 100%;
    padding: 4rem 2rem 2rem 2rem;
    text-align: center;
}

.why-block p {
    margin-bottom: 2rem;
    font-size: 21px;
}

.why-block h1 {
    font-weight: bold;
    font-size: 41px;
    margin-bottom: 2rem;
}

.flexWhoTiles {
    display: flex;

    flex-direction: column;
    align-items: center;
}

@media (min-width: 900px) {
    .why-block {
        
    }

    .why-block h1 {
        
    }

    .why-block p {
        
    }

    
}

@media (min-width: 1400px)
{
    .why-block {
        
        display: flex;
        justify-content: space-between;
    }
    .why-block .whyQokkaDivText {
        width: 48%;
    }
    .why-block .forWhoQokkaDiv {
        width: 48%;
    }
    .flexWhoTiles {
        display: flex;
        flex-direction:column;
        align-items: None;
    }
    .who-tile {
        max-width: calc(60% - 1rem);
        margin-bottom: 0 !important;
    }
    .responsiveFlexer{
        display: flex;
        flex-direction: row;
    }
    .why-block .spacerDiv{
        padding-bottom: 6rem;
    }

}