.historique {
    padding-top: 2.2rem;
    
}

.historique h5 {
    color: #0061d1 !important;
    
    padding-left: 2rem;
    padding-bottom: 0.5rem;
}

.historique .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg_bleu.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.historique .background_div h2 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.historique .div_flex_helper {
    display: flex;
    flex-direction: column;
    align-items: center ;
}

.historique .div_flex_helper .div_laws {
    
    background: url(../icons/guillemets_background.svg) no-repeat right bottom;
    background-color: #e3f0ff;
    background-size: 20%;
    background-position-x: calc(100% - 25px);
    background-position-y: calc(100% - 15px);
    color: #8390b3;
    width: 82%;
    border-radius: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    
}
.historique .div_flex_helper .blue_gradient_helper{
    background: linear-gradient(to right,#98f6d1, #66cde2 20%);
    border-radius: 50%;
    height: 20rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 5rem;
}

.historique .div_flex_helper .total_consultations_div{
    background:white;
    border-radius: 50%;
    width: 86%;
    height: 86%;
    color: #8390b3;
    font-weight: 600;
    font-size: 1.9rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.historique .consultations_div_container {
    background: linear-gradient(to right top, #66cde2 60%,#4a9cea);
    padding-top: 6rem;
    padding-bottom: 6rem;
    padding-right: 2.6rem;
    padding-left: 2.6rem;
}

.historique .consultations_div_container .single_consultation {

    padding: 3rem;
    display: grid;
    grid-template-columns: repeat(2,2fr);

}

.historique .consultations_div_container .consultations_table {
    
    height: 45rem;

}

@media (min-width: 1400px)
{
    .historique .background_div {
        margin-right: 34%;
        margin-left: 34%;
        background-position-x: calc(95%);
    }

    .historique h5{
        margin-left: 8%;
    }
    .historique .div_flex_helper .div_laws {
        
        padding: 2rem;
        height: 9rem;
        width: 27%;

    }
    .historique .div_for_flex_display
    {

        display: flex;
        flex-direction:row;
        align-items: flex-start;
        justify-content: center;
        margin-top: 4rem;
        padding-top: 2rem;
    }

    .historique .div_for_flex_display .div_coordonnees
    {

        margin-top: 0;
    }

    .historique .div_for_flex_display .separator_div
    {
        width: 0.075rem;
        height: 28rem;
        background-color: #b0b8ce;
    }
    .historique .consultations_div_container {
        display: flex;
        flex-direction:column;
        align-items: center;
    }
    .historique .consultations_div_container .utils_div{
        width: 45%;
    }
    .historique .consultations_div_container .single_consultation{
        
        grid-template-columns: repeat(3,1fr)
    }
    .historique .consultations_div_container .single_consultation p span{
        text-shadow: 1px 0px 0px #8390b3; /* the textcolor */
        padding-right: 1px;
        letter-spacing: 1px;
    }

    .historique .consultations_div_container .consultations_table {
    
        height: 45rem;
        width: 70%;
    }

}