

html {
  font-size: 18px;
}

@font-face {
  font-family: "RubikRegular";
  src: url("./fonts/Rubik/static/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RubikRegular";
  src: url("./fonts/Rubik/static/Rubik-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "RubikMedium";
  src: url("./fonts/Rubik/static/Rubik-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RubikLight";
  src: url("./fonts/Rubik/static/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RubikLight";
  src: url("./fonts/Rubik/static/Rubik-LightItalic.ttf") format("truetype");
  font-style: italic
}

@font-face {
  font-family: "RubikBlack";
  src: url("./fonts/Rubik/static/Rubik-Black.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'RubikRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #383e4d !important;
  min-width: 300px;
}

ul {
  list-style: none;
  padding-inline-start: 0 !important;
}

ul li {
  background: url(./icons/list-circle.svg) no-repeat left top;
  background-position-y: 0.6rem;
  background-size: 0.75rem;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.btn {
  font-size: 24px !important;
  padding: 0.5rem 3rem !important;
  border-radius: 35px/50% !important;
  font-family: 'RubikMedium' !important;
}

.medium {
  font-family: 'RubikMedium' !important;
}

.light {
  font-family: 'RubikLight' !important;
}

.anchor {
  position: relative;
  top: -150px;
  padding-top: 150px;
  margin-bottom: -150px;
  z-index: 0;
  width: 0 !important;
}

@media (min-width: 900px) {
  .btn {
    font-size: 18px !important;
    padding: 0.5rem 3rem !important;
    border-radius: 25px/50% !important;
  }

  #scroll-to-top {
    display: none !important;
  }
}

@media (max-width: 499px) {
  .btn {
    width: 100%;
  }
}


.MuiButton-root, button {
  text-transform: unset !important;
}

a:hover {
  color:#383e4d;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.MuiButton-contained, .MuiButton-outlined, .MuiFab-root {
  border: 2px #9af7d0 solid !important;
}

.MuiButton-contained:hover, .MuiFab-root:hover {
  color: #0061d1 !important;
  background-color: #7affc6 !important;
  box-shadow: 0px 0px 8px 2px rgba(33, 75, 57, 0.1),
  0px 0px 10px 2px #9af7d0 !important
}

.MuiButton-outlined:hover {
  color: #0061d1 !important;
  background-color: #cff4f7 !important;
  box-shadow: 0px 0px 8px 2px rgba(33, 75, 57, 0.1),
  0px 0px 10px 2px #9af7d0 !important
}

div.error-message {
  width: 100%;
  color: #d32f2f !important;
  font-size: 19px !important;
}

@media (min-width: 900px) {
  div.error-message {
    font-size: 21px !important;
  }
}