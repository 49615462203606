.MuiFormControl-root label {
    position: static;
    font-size: 16px !important;
    margin: 1rem 0rem 0.5rem 0rem !important;
    color: #383e4d !important;
    transform: none !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
}

.MuiInputBase-root {
    background-color: #fff !important;
    width: calc(100%) !important;
}

.MuiInputBase-formControl {
    padding: 0rem !important;
    color: #383e4d !important;
    font-family: 'RubikLight' !important;
    font-size: 21px !important;
    background-color: #fff !important;
    border-radius: 20px !important;
    border: 2px solid #fff !important;
    margin: 0rem 0rem 0rem 0rem !important;
    box-shadow: 5px 5px 8px 5px rgba(0, 0, 0, 0.05) !important;
    width: initial;
}

.MuiFormControl-root {
    font-size: 16px !important;
}

.MuiFormControl-root .MuiInputBase-input {
    margin: 0px !important;
    padding: 1rem 2rem !important;
    border-radius: inherit !important;

}

.MuiInputBase-formControl:focus-within {
    border-color: #66cde2 !important;
}

.MuiFormControl-root .MuiFilledInput-input {
    color: #000 !important;
    font-size: 21px !important;
}

.MuiFilledInput-input[value=""] {
    font-style: italic !important;
    font-family: "RubikLight" !important;
    color: #8390b3 !important;
}

.MuiFormControl-root input::after {
    border: none !important;
}

.MuiFilledInput-root:before {
    border-bottom: inherit !important;
}

.MuiFilledInput-root:after {
    border-bottom: inherit !important;
    transform: none !important;
    transition: none !important;
}

.MuiInputBase-adornedEnd .MuiInputBase-input {
    padding: 0rem !important;
    padding: 1rem 0rem 1rem 2rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin: 0rem !important;
}

.MuiInputAdornment-positionEnd {
    background-color: #fff !important;
    margin: 0rem !important;
    padding: 0rem 1rem !important;
    height: 100% !important;
}

.MuiInputAdornment-positionEnd button {
    margin: 0rem !important;
    padding: 0rem !important;

}

.MuiFormHelperText-root {
    padding: 0rem 0rem 0rem 2rem !important;
    color: #d32f2f !important;
}

.err-required .MuiInputLabel-asterisk {
    color: #d32f2f !important;
}

.MuiFormControl-root .MuiInputBase-input {
    margin: 0px !important;
    padding: 1rem 2rem !important;
}

.MuiFormControlLabel-root {
    margin: 2rem 0rem 0.5rem 0rem !important;
    padding: 0 !important;
    display: flex !important;
    align-items: flex-start !important;
}

.MuiCheckbox-root {
    padding: 0 !important;
    box-shadow: 5px 5px 8px 5px rgba(0, 0, 0, 0.05) !important;
}

.MuiFormControlLabel-label {
    padding: 2px 0 0 1rem !important;
    font-size: 16px !important;
}

textarea {
    height: auto !important;
}

.MuiFormControl-root textarea:empty {
    font-style: italic !important;
    font-family: "RubikLight" !important;
    color: #8390b3 !important;
}

@media (min-width: 900px) {
    .MuiFormControlLabel-label {
        font-size: 21px !important;
    }

    .MuiFormControl-root label {
        font-size: 21px !important;
    }

    .MuiFormHelperText-root {
        font-size: 19px !important;
    }

    .MuiInputBase-formControl {
        font-size: 28px !important;
    }

    .MuiFormControl-root .MuiFilledInput-input {
        font-size: 28px !important;
    }

    .MuiCheckbox-root {
        margin-top: 4px !important;
    }
}