.blue-bg-block {
    background: linear-gradient(45deg, #0061d1, 50%, #0061d1, #66cde2);
    color: #fff;
}

.try-curve {
    padding: 3rem 1rem 0 1rem;
    background: url(../icons/try-curve.svg) no-repeat center bottom;
    padding-bottom: 280px;
    background-position-x: 20%;
}

.blue-bg-block h1 {
    margin-left: 1rem;
    margin-right: 2rem;
    margin-bottom: 2rem;
    font-size: 48px;
    font-weight: 200;
    line-height: 3.5rem;    
}

.blue-bg-block ul {
    list-style: none;
    padding-inline-start: 0.2rem !important;
}

.blue-bg-block ul li {
    background: url(../icons/Q.svg) no-repeat left top;
    padding-left: 2rem;
    margin-top: 1.0rem;
}

.blue-bg-block .btn-container {
    display: flex;
    justify-content: center;
}

.qokka-bg-block {
    position: relative;
    top: -200px;
    background: url(../icons/Qbg.svg) no-repeat left top;
    font-size: 24px;
    background-position-x: calc(100% + 50px);
    padding: 3.5rem 4rem 0rem 2rem;
    min-height: 270px;
    margin-bottom: -200px;
}

.qokka-bg-block p {
    max-width: 550px;
}

.qokka-photos {
    display: none;   
}

.discover-block h1 {
    font-weight: bold;
    font-size: 41px;
    color: #0061d1;
}

.blue-medium {
    margin-top: 1.5rem;
    font-size: 30px;
    font-family: "RubikMedium" !important;
    color: #0061d1;
}

.discover-block {
    position: relative;
    z-index: 1001;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.discover-block img {
    max-width: 100%;
    padding: 0 2rem;
}

.discover-block .description {
    margin-top: 2rem;
    text-align: left;
    font-size: 21px;
}

.blue-bold-big {
    font-size: 36px;
    color: #0061d1;
    margin: 0 2rem;
    font-weight: bold;
    line-height: 3rem;
    margin-top: 4rem;
    margin-bottom: 3rem;
}

.advantages-block {
    background-color: #e3f0ff;
    position: relative;
    z-index: 900;
    top: -250px;
    margin-bottom: -250px;
}

.advantages-curve {
    padding: 280px 2rem 2rem 2rem;
    background: url(../icons/advantages-curve.svg) no-repeat center top;
    background-position-x: 20%;    
}

.advantage-tile {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 3rem 1.5rem 2rem 1.5rem;
    border-radius: 50px;
    box-shadow: rgba(128, 128, 128, 0.15) 5px 5px 10px 7px;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;
}

.advantage-tile h1 {
    font-size: 24px;
    font-weight: bold;
    color: #4a9cea;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.advantage-tile img {
    max-width: 100%;
    width: 80px;
}

.advantages-curve2 {
    background: url(../icons/advantages-curve2.svg) no-repeat right bottom;
    background-size: contain;
}

.home .test-qokka-block {
    position: relative;
    z-index: 1002;
    top: -1px;
    margin-bottom: -1px;
}

.who-block {
    width: 100%;
    padding: 4rem 2rem 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.who-tiles {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.who-block h1 {
    font-weight: bold;
    font-size: 41px;
    margin-bottom: 3rem;
    text-align: center;
}

.who-block h2 {
    font-weight: bold;
    font-size: 30px;
    display: inline-block;
    margin-bottom: 0;
}

.who-block h1, .who-block h2 {
    color: #66cde2;
}

.who-block ul {
    list-style: none;
    padding-inline-start: 0 !important;
}

.who-block ul li {
    background: url(../icons/list-circle.svg) no-repeat left top;
    background-position-y: 0.6rem;
    background-size: 0.75rem;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    font-size: 21px;
}

.who-block .who-tile {
    padding: 2rem;
    background-color: #e3f0ff; 
    border-radius: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.who-block .who-tile .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.who-block .who-tile .title img {
    max-width: 100%;
    width: 60px;
}

.btn {
    font-size: 26px !important;
}

.iso-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2rem 0 2rem;
}

.iso-block img {
    max-width: 100%;
    width: 120px;
}

.iso-block h1 {
    font-size: 41px;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.iso-block .iso-number {
    font-size: 18px;
    font-weight: bold;
    padding: 0.5rem 1rem;
    display: inline-block;
    margin-bottom: 1rem;
}

#iso-31000 {
    background-color: #0061d1;
    color: #fff;
}

#iso-26000 {
    background-color: #66cde2;
}

#iso-19600 {
    background-color: #4a9cea;
    color: #fff;
}

.iso-block h2 {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 1rem;
}

.iso-block p {
    text-align: justify;
}

.iso-tile {
    margin-bottom: 3rem;
}

#hide-junction1 {
    display: block;
    height: 2px;
    background: #fff;
    position: relative;
    top: -1px;
    margin-bottom: -1px;
}

#hide-junction2 {
    display: block;
    height: 2px;
    background: #fff;
    position: relative;
    z-index: 1000;
    top: -249px;
}

.home .WhoIsWhoButtonStyle {
    color: #383e4d;
    background-color: #9af7d0;
    text-align: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 3rem;
    max-width: calc(90%);
    font-family: 'RubikMedium';
}

/* 900 */
@media (min-width: 900px) {
    .try-curve {
        background-position-x: 50%;
    }

    .qokka-bg-block {
        position: relative;
        width: 100%;
        background-position-x: 95%;
        top: -280px;
        margin-bottom: -280px;
    }

    .qokka-bg-block p {
        position: relative;
        left: 10%;
        top: 20px;

    }
    
    .qokka-photos {
        display: block;
        position: relative;
        width: 420px;
        height: 401px;
        background: url(../icons/Docs_identites.svg) no-repeat left top;        
        background-size: cover;
        top: -333px;
        margin-bottom: -348px;
        left: 70%;
    }

    .discover-block h1 {
        font-size: 65px;
    }

    .discover-block .blue-medium {
        font-size: 30px;
        margin-bottom: 4rem;
        max-width: 45%;
    }

    .discover-block .description {
        font-size: 24px !important;
        max-width: 40%;
        background-color: #e3f0ff;
        padding: 2rem;
    }

    .discover-block img {
        max-width: 300px;
    }

    .discover-block .horizontal-block {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5%;
    }

    .advantages-curve {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;        
        background-position-x: 50%;
        padding-top: 300px;
        justify-content: space-between;
        align-items: center;
    }

    .advantage-tile {
        max-width: calc(50%);
        position: relative;
    }

    .advantage-tile:nth-child(2n) {
        top: 250px;
        left: -1rem;
    }

    .advantage-tile:nth-child(1) {
        left: 15%;
        z-index: 200;
    }

    .advantage-tile:nth-child(2) {
        z-index: 199;
    }

    .advantage-tile:nth-child(3) {
        z-index: 198;
    }

    .advantage-tile:nth-child(4) {
        z-index: 197;
    }

    .advantage-tile:nth-child(5) {
        z-index: 196;
        top: 20px;
        left: 15%;
    }

    .advantages-curve2 {
        background: none;
    }

    .who-tiles {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 2rem;
        align-items: stretch;
    }

    

    .who-block h1 {
        font-size: 65px;
        margin-bottom: 6rem;
    }

    .iso-block h1 {
        font-size: 65px;
        margin-bottom: 6rem;
    }

    .iso-block .iso-tile {
        margin-bottom: 4rem;
    }

    #hide-junction2 {
        display: block;
        height: 2px;
        position: relative;
        z-index: 1000;
        top: -99px;
    }
}

/* 1400 */
@media (min-width: 1400px) {
    .try-curve {
        background-size: 100%;
        background-position-y: calc(100% + 1px) ;
        padding: 3rem 10% 200px 10%;
    }

    .blue-bg-block h1 {
        width: 50%;
        font-size: 65px;
        line-height: 4rem;
    }

    .blue-bg-block ul {
        margin-left: 1rem;
    }

    .blue-bg-block ul li {
        margin-top: 0.5rem;
    }

    .blue-bg-block .btn-container {
        justify-content: left;
    }

    .qokka-photos {
        width: 852px;
        height: 629px;
        margin-bottom: -450px;
        left: 55%;
        top: -500px
    }

    .qokka-bg-block {
        background-position-x: 70%;
        top: -161px;
        margin-bottom: -161px;
    }

    .qokka-bg-block p {
        left: calc(45% - 150px);        
    }    

    .blue-bold-big {
        max-width: 35%;
    }

    .advantages-block {
        top: -100px !important;
        margin-bottom: -100px;
    }

    .advantages-curve {
        background-size: 100%;
        background-position-y: -1px;
        padding: 80px 10% 3rem 10%;
    }

    .advantage-tile {
        max-width: calc(26%);
    }
    
    .advantage-tile:nth-child(2n) {
        top: 0;
        left: 0;
    }
    
    .advantage-tile:nth-child(1) {
        left: 12%;
        z-index: 200;
    }
    
    .advantage-tile:nth-child(2) {
        z-index: 201;
        top: 200px;
    }
    
    .advantage-tile:nth-child(3) {
        z-index: 202;
        top: -40px;
        left: -12%;
    }
    
    .advantage-tile:nth-child(4) {
        z-index: 203;
        top: -85px;
    }
    
    .advantage-tile:nth-child(5) {
        z-index: 204;
        top: -250px;
        margin-bottom: -250px;
        left: 0;
    }

    .who-block {
        padding: 6rem 5% 6rem 5%;
    }

    .iso-block {
        padding: 4rem 10% 6rem 10%;
    }

    #hide-junction2 {
        top: -249px;
    }
}

@media (min-width: 1500px) {
    .who-tiles {
        gap: 1rem;
        justify-content: space-between;
    }
    
}