.not_connected_change_password .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.not_connected_change_password .background_div h2 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.not_connected_change_password .utils_desktop_mode .container_utils{

    background-color: #e3f0ff;
    padding-top: 3rem;
    padding-bottom: 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding-left: 2rem;
    padding-right: 2rem;

}

.not_connected_change_password .utils_desktop_mode .container_utils form{

    width: 100%;
    
}

@media (min-width: 1400px)
{
    .not_connected_change_password .utils_desktop_mode{
        display: flex;
        flex-direction: column;
        align-items: center;
        align-items: center;
        margin-bottom: 8rem;

    }
    
    .not_connected_change_password .background_div {
        margin-right: 34%;
        margin-left: 34%;
        background-position-x: calc(95%);

    }

    .not_connected_change_password .utils_desktop_mode .container_utils {

        width: 35%;
        padding-left: 10rem;
        padding-right: 10rem;

    }

    .not_connected_change_password .utils_desktop_mode .container_utils form{
        width: 170%;
        
    }
}