/* Styles for the container */
.sos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    max-width: 700px;
    background: rgba(255, 255, 255, 0.8);
    padding: 1.5rem;
    margin-top: 20px;
    margin-bottom: 70px;
  }
  
  /* Styles for the inner container */
  .inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  
  /* Styles for the image */
  .sos-image {
    height: 116px;
    width: 116px;
  }
  
  /* Styles for the text */
  .sos-text {
    color: black;
  }
  
  /* Styles for the link */
  .sos-link {
    text-decoration: none;
    color: #1890ff;
  }
  
  /* Media query for phones */
  @media only screen and (max-width: 600px) {
    /* Adjustments for smaller screens */
    .sos-image {
      height: 80px;
      width: 80px;
    }
  
    .sos-text {
      font-size: 14px;
    }

    .sos-container {
        max-width: 400px;
        margin: 40px;
        padding: 15px;
        max-height: 300px;
    }

    .sos-text p {
        margin-bottom: 0;
      }
  }
  
