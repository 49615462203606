
.createNewPassword .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.createNewPassword .background_div h2 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.createNewPassword .utils_div {

    background-color: #e3f0ff !important;
    border-radius: 20px !important;
    padding: 4rem 2rem 4rem 2rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    
}

.createNewPassword .utils_div > form{

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1.5rem;
}

@media (min-width: 1400px){

    .createNewPassword .background_div {
        margin-right: 34%;
        margin-left: 34%;
        background-position-x: calc(95%);
    }

    .createNewPassword {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .createNewPassword .utils_div {

        width: 30%;
        
    }

}