.qokkaMonCompte {
    padding-top: 2.2rem;
    
}

.qokkaMonCompte p.hello {
    color: #0061d1 !important;
    margin: 0 0rem;
    font-size: 24px !important;
    padding: 0 2rem 0.5rem 2rem;
}

.qokkaMonCompte .background_div {
    text-align: center;
    display: flex;
    flex-direction: column;
    background: url(../icons/Qbg.svg) no-repeat right top;
    background-size: 200px;
    background-position-x: calc(100% + 10px);
    background-position-y: calc(40px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
    padding-bottom: 6rem;
}

.qokkaMonCompte .background_div h1 {
    font-weight: bold;
    font-size: 45px !important;
    font-family: 'RubikRegular';
}

.qokkaMonCompte .current_package_details {
    background: linear-gradient(#0061d1,#0061d1 80%, #4a9cea);
    
    border-radius: 3rem;
    padding: 2.5rem 1rem 1.3rem 1rem;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;


}

.qokkaMonCompte .current_package_text, .qokkaMonCompte .current_package {
    color: #fff;
    font-size: 30px !important;
}
    
.qokkaMonCompte .current_package
{
    margin: 0;
    padding-bottom: 0rem;
    text-transform: uppercase;
}

.qokkaMonCompte .used_credit, .qokkaMonCompte .extra_credit{
    font-size: 19px !important;
    font-family: 'RubikRegular' !important;
    font-weight: bold;
    padding: 0rem 1rem;
    margin: 0rem 1rem;
    text-transform: uppercase;    
}

.qokkaMonCompte .used_credit {
    margin: 1rem 1rem;
}
.qokkaMonCompte .extra_credit {
    color: #fff;
    background-color: #0061d1;
    border: 1px solid #fff;
}

.qokkaMonCompte .current_package_details .ul_div_container ul {
    list-style: none;
    padding-inline-start: 0 !important;
    color : white;
    text-align:left;
    margin-top: 2rem;
}

.qokkaMonCompte .current_package_details .ul_div_container ul li {
    background: url(../icons/list-circle_different_color.svg) no-repeat left top;
    background-position-y: 7px !important;
    background-size: 0.75rem;
    padding-left: 1.5rem;
    margin-top: 0.5rem;
    font-size: 21px;
}

.qokkaMonCompte .div_for_flex_display {
    margin-top: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e3f0ff;
}

.qokkaMonCompte .div_for_flex_display .div_coordonnees {
    background-color: #e3f0ff;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    
}

.qokkaMonCompte .div_for_flex_display .div_coordonnees h3 {
    padding-bottom: 0.8rem;
}

.qokkaMonCompte .div_for_flex_display .div_paiement_info {
    background-color: #e3f0ff;
    padding: 1rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    

}
.qokkaMonCompte .div_for_flex_display .separator_div
    {
        height: 0.075rem;
        width: 94%;
        background-color: #b0b8ce;

    }

.qokkaMonCompte .ul_dates_account {
    margin-left: 1rem;
}

.qokkaMonCompte .ul_dates_account li
{
    font-family: 'RubikLight' !important;
    font-size: 18px !important;
}

.qokkaMonCompte .btn
{
    font-size: 18px !important;
    width: auto !important;
    padding: 1rem 2rem !important;
}

.qokkaMonCompte .resiliate {
    margin-top: 1rem !important;
    font-size: 16px !important;
}

.qokkaMonCompte .spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.qokkaMonCompte .subtitle {
    font-size: 30px !important;
    padding-bottom: 2rem;
}

.qokkaMonCompte .user-blue, .qokkaMonCompte .blue {
    color:#0061d1 !important;
}

.qokkaMonCompte .div_coordonnees
{
    border-bottom: 1px solid #b0b8ce;
}

.qokkaMonCompte .div_coordonnees,
.qokkaMonCompte .div_paiement_info {
    width: calc(100% - 2rem);
}

.btn {
    width: auto;
}

#change-pm {
    margin: auto;
}
@media (min-width: 900px)
{
    .qokkaMonCompte .div_coordonnees
    {
        margin: 0 !important;
        padding-left: 2rem !important;
        border-right: 1px solid #b0b8ce;
        border-bottom: none;
    }

    .qokkaMonCompte .div_paiement_info {
        margin: 0 !important;
        padding-right: 2rem !important;
    }
    
    .qokkaMonCompte .background_div {
        margin: auto;
        background-position-x: calc(50% + 210px);
        background-size: 240px;
        align-items: center;
    }

    .qokkaMonCompte .background_div h1 {
        font-size: 60px !important;
        max-width: 450px;
    }

    .qokkaMonCompte .current_package_text, .qokkaMonCompte .current_package
    {
        font-size: 33px !important;
    }

    .qokkaMonCompte .current_package_details{
        max-width: 700px;
        display: flex;
        margin: auto;
    }

    .qokkaMonCompte p.hello{
        padding-left: 20%;
        padding-right: 20%;
    }

    .qokkaMonCompte .div_for_flex_display
    {
        display: flex;
        flex-direction:row;
        align-items: flex-start;
        justify-content: center;
        margin-top: 6rem;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .qokkaMonCompte .used_credit,
    .qokkaMonCompte .extra_credit
    {
        font-size: 21px !important;
        padding: 0.25rem 2rem;
    }

    .qokkaMonCompte .ul_dates_account li, .qokkaMonCompte .btn {
        font-size: 20px !important;
        margin-top: 1.5rem !important;
    }

    .qokkaMonCompte .btn {
        padding: 1rem 3rem !important;
        border-radius: 50px !important;
    }

    .qokkaMonCompte .resiliate {
        font-size: 18px !important;
        margin-bottom: 1rem;
    }

    .qokkaMonCompte .div_for_flex_display .div_coordonnees
    {
        margin-top: 0;
    }

    .qokkaMonCompte .div_for_flex_display .separator_div
    {
        width: 0.075rem;
        height: 28rem;
        background-color: #b0b8ce;

    }

    .qokkaMonCompte p.hello {
        font-size: 27px !important;
    }
}

@media (min-width: 1400px) {
    .qokkaMonCompte .div_coordonnees {
        padding-left: 10% !important;
    }

    .qokkaMonCompte .div_paiement_info {
        padding-right: 10% !important;
    }
}