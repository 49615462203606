.MuiAppBar-root a {
    font-family: 'RubikRegular', sans-serif !important;
    
}

#menu-pages a {
    padding: 0 0.5rem !important;
}

.selected-menu {
    text-decoration: underline !important;
}

.menu-btn {
    font-size: 16px !important;
    padding: 0.2rem 2rem !important;
    border-radius: 20px/50% !important;
}

#navbar-warp, #navbar-warp-connected {
    justify-content: flex-end !important;
    gap: 0.25rem;
    align-items: center !important;
}

.MuiList-root .MuiButton-root {
    padding: 0 0.5rem !important;
}

#menu-pages-connected a.MuiButton-contained, #navbar-warp-connected a.MuiButton-contained {
    color: #fff !important;
    background-color: #0061d1 !important;
    border-color: #0061d1 !important;
    font-family: "RubikMedium" !important;
    font-size: 16px !important;
    padding: 0.25rem 1rem !important;
    border-radius: 30px;
    margin-right: 1rem;
}

#plus-icon {
    width: 20px !important;
    height: 20px !important;
}

#menu-pages-connected a.MuiButton-contained
{
    position: relative;
    top: -0.45rem;
}

#navbar-warp-connected a.MuiButton-contained {
    min-width: 0 !important;
    margin-right: 0rem !important;
    padding: 0.28rem !important;
    border-radius: 50%/50%;
}

#navbar-warp-connected a.MuiButton-contained .MuiButton-startIcon {
    margin: 0 !important;
}

#navbar-warp-connected #plus-icon {
    position: relative;
}

#menu-pages-connected a.MuiButton-contained:hover, #navbar-warp-connected a.MuiButton-contained:hover {
    background-color: #1979e6 !important;
    box-shadow: 0px 0px 8px 2px rgba(33, 75, 57, 0.1),
            0px 0px 10px 2px #1979e6 !important;
}

#menu-pages-connected a.MuiButton-contained img {
    margin-right: 0.5rem;
}

#icon-add-document {    
    background-color: #0061d1 !important;
    border-color: #0061d1 !important;
    border-radius: 50%;
}

@media (max-width: 899px) {
    #menu-mobile-appbar .MuiPopover-paper { 
        position: fixed !important;
        bottom: 0;
        top: 0 !important;
        left: auto !important;
        right: 0 !important;
        width: calc(100% - 16px) !important;
        max-height: 100% !important;
        border-radius: 0 !important;
        padding: 0 !important;
    }

    #menu-mobile-appbar .MuiBackdrop-root {
        background-color: #0004 !important;
        transition: opacity 500ms ease-in-out !important;
    }

    #menu-mobile-appbar .menu-container {
        padding: 3rem 2rem 2rem 2rem!important;
    }

    #menu-mobile-appbar #menu-user {
        padding: 0 !important;
        flex-direction: column !important;
        align-items: center;
        text-align: center;
        gap: 1rem;

    }
    #menu-mobile-appbar .MuiList-root {
        align-items: flex-start;
        margin-bottom: 4rem !important;
        gap: 1rem;
    }
    #menu-mobile-appbar .menu-container .MuiButton-root {
        color: #383e4d !important;
        font-size: 21px !important;
        font-family: 'RubikMedium' !important;
        padding: 0.2rem;
    }

    #menu-mobile-appbar #menu-user .MuiButton-root {
        border-radius: 35px/50% !important;
        margin: 0 !important;
        font-size: 18px !important;
        min-width: 250px !important;
        padding-top: 0.75rem !important;
        padding-bottom: 0.75rem !important;        
    }
}

@media (max-width: 399px) {
    #navbar-warp, #navbar-warp-connected {
        width: 100%;
    }
}

@media (max-width: 469px) {
    #menu-connected-appbar #navbar-warp-connected {
        width: 100%;
    }
}

@media (min-width: 900px) {
    #menu-mobile-appbar #navbar-warp, #navbar-warp-connected {
        flex-grow: 0;
    }
    #menu-mobile-appbar #menu-appbar .MuiPopover-paper {        
        padding: 0.5rem;
        box-shadow: 0px 6px 10px 5px rgb(0 0 0 / 20%),
        0px 12px 5px 1px rgb(0 0 0 / 14%),
        0px 2px 3px 2px rgb(0 0 0 / 12%) !important;
    }
}

@media (min-width: 1400px) {
    .MuiAppBar-root {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
}

#menu-connected-appbar .MuiPopover-paper {
    position: fixed !important;
    bottom: 0;
    top: 0 !important;
    left: auto !important;
    right: 0 !important;
    width: calc(100% - 16px) !important;
    max-height: 100% !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

#menu-connected-appbar .MuiBackdrop-root {
    background-color: #0004 !important;
    transition: opacity 500ms ease-in-out !important;
}

#menu-connected-appbar .menu-container {
    padding: 2rem 2rem 2rem 2rem !important;
}

#menu-connected-appbar #menu-user {
    padding: 0 !important;
    flex-direction: column !important;
    align-items: center;
    text-align: center;
    gap: 1rem;

}

#menu-connected-appbar .MuiList-root {
    align-items: flex-start;
    margin-bottom: 4rem !important;
    gap: 1rem;
}

#menu-connected-appbar .menu-container .MuiButton-root {
    color: #383e4d !important;
    font-size: 21px !important;
    font-family: 'RubikMedium' !important;
    padding: 0.2rem;
}

#menu-connected-appbar #menu-user .MuiButton-root {
    border-radius: 35px/50% !important;
    margin: 0 !important;
    font-size: 18px !important;
    min-width: 250px !important;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
}

#menu-connected-appbar hr {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

#menu-connected-appbar .sub-title {
    font-size: 14px !important;
    padding: 0rem 0.25rem !important;
}

#menu-connected-appbar .name {
    font-size: 24px !important;
    padding: 0rem 0.25rem !important;
    color: #0061d1 !important;
}

#menu-connected-appbar .menu-title {
    display: flex;
    padding: 2rem 2rem 0 2rem !important;
}

#menu-connected-appbar .name-container {
    display: flex;
    flex-direction: column;
}

@media (min-width: 900px) {
    #menu-connected-appbar .MuiPopover-paper {
        position: fixed !important;
        bottom: 0;
        top: 0 !important;
        left: auto !important;
        right: 0 !important;
        width: calc(100% - 16px) !important;
        max-height: 100% !important;
        border-radius: 0 !important;
        padding: 0 !important;
        width: auto !important;
    }

    #menu-connected-appbar .name-container {
        margin-right: 30px;
    }

    #menu-connected-appbar #menu-user {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    #menu-connected-appbar #menu-user .menu-btn {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
        width: 100%;
    }
}