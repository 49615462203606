.signin .info-block {
    background: url(../icons/Qbg.svg) no-repeat left top;
    background-position-x: calc(100% + 35px);
    background-size: 160px;
    padding: 0rem 1rem;
    text-align: center;
}

.signin .info-block h1 {
    font-size: 45px !important;
    font-weight: bold !important;
    margin: 5rem 0 !important;
}

.signin .info-block p {
    font-size: 24px !important;
    color: #0061d1;
    margin: 0rem 2rem 2rem 2rem;
}

.signin .connect {
    margin-top: 4rem !important;
    margin-bottom: 2rem !important;
}

.signin form a {
    font-size: 16px !important;
}

.signin a {
    text-decoration: underline;
    color: #0061d1 !important;
}

.signin form{
    background-color: #e3f0ff !important;
    border-radius: 20px !important;
    padding: 4rem 2rem 4rem 2rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signin form :nth-child(4){
    width: 100%;
    height: 3rem;
}

.signin .login-text-en-bas{
    text-align: center;
    margin-top: 2rem !important;
    font-size: 21px !important;
}

.signin .login-text-en-bas p {
    margin: 0 !important;
}


@media (min-width: 900px) {

    .signin .info-block p {
        font-size: 28px !important;
    }

    .signin .info-block {

        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        background: url(../icons/Qbg.svg) no-repeat !important;
        background-position-x: calc(50% + 200px) !important;
        background-position-y: calc(50px) !important;
        background-size: 250px !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: normal;
        text-align: center;
    }
    .signin .login-text-en-bas {
        padding-top: 3rem;
        padding-bottom: 7rem;
    }
    .signin h1 {
        font-size: 60px !important;
    }

    .signin form {
        margin-left: 2rem !important;
        margin-right: 2rem !important;
    }

    .signin form a {
        font-size: 22px !important;
    }

    .signin .connect {
        font-size: 26px !important;
    }
}

@media (min-width: 1400px) {
    .signin form {
        margin-left: 20% !important;
        margin-right: 20% !important;
    }
}