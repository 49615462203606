.payment .form {
    background-color: #e3f0ff !important;
    border-radius: 20px !important;
    padding: 4rem 2rem 4rem 2rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.payment h2 {
    color: #0061d1 !important;
    font-size: 30px !important;
    font-family: "RubikMedium" !important;
    text-align: center;
}
.payment .form form {
    width: 100%;
}

.payment .form h2 {
    margin-bottom: 2rem !important;
}

.payment .pay {
    margin-top: 2rem !important;
    min-width: 300px;
}

.payment .already-an-account {
    display: flex;
    flex-direction: column;
    align-items: center;    
}

.payment .already-an-account p {
    margin-top: 2rem;    
    margin-bottom: 0;
    font-size: 21px !important;
}

.payment .already-an-account a {
    font-family: "RubikMedium";
    font-size: 21px !important;
    text-decoration: underline;
}

.payment h1 {
    margin: 0 !important;
    padding: 2rem 2rem 0rem 2rem!important;
    font-size: 45px;
    font-weight: bold;
    text-align: center;
    position: relative;
    z-index: 500;
}

.payment .chosen-formula {
    background: url(../icons/Qbg.svg) no-repeat center top;
    background-size: 150px;
    padding: 6rem 2rem 0rem 2rem!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -60px;

}
.payment .chosen-formula .name {
    color: #0061d1;
    font-size: 30px !important;
    font-family: "RubikMedium" !important;
    text-align: center;
    text-transform: uppercase;
}
.payment .consultations {
    color: black;
    background-color: #4a9cea;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.25rem 1rem;
    font-weight: bold;
    font-size: 19px;
    text-transform: uppercase;
}
.payment .price {
    font-weight: bold;
    font-size: 29px;
}
.payment .comment, .payment .pricePerConsultation {
    font-size: 11px;
}

.payment .stroken {
    font-weight: bold;
    font-size: 23px;
    text-decoration: line-through;
}

.payment .Mui-error {
    color: #d32f2f;
}

.payment .step-image-container {
    display: flex;
    justify-content: center;
}

.payment .step-image-container img {
    padding: 2rem;
    max-width: 100%;
    width: calc(600px - 4rem);
}

.payment .spinnerContainer {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

@media (min-width: 900px)
{
    .payment h1 {
        font-size: 55px;
    }
    .payment h2 {
        font-size: 33px;
    }

    .payment .chosen-formula h2, .payment .chosen-formula .name {
        font-size: 44px !important;
    }

    .payment .splitter {
        display: flex;
        flex-direction: row;
        padding: 4rem 2rem;
        gap:2rem;
        justify-content: space-between;       
    }
    .payment .chosen-formula {
        background-size: 300px !important;
        padding: 14rem 1rem 0rem 1rem !important;
        top: 0px !important;
    }
    .payment .right-panel {
        width: 60%;
    }

    .payment .consultations {
        font-size: 29px !important;
    }

    .payment .stroken {
        font-size: 24px !important;
    }

    .payment .price {
        font-size: 42px !important;
    }

    .payment .comment, .payment .pricePerConsultation {
        font-size: 16px !important;
    }

    .payment .already-an-account p {
        font-size: 24px !important;
    }
    
    .payment .already-an-account a {
        font-size: 24px !important;
    }

    .payment .step-image-container img {
        width: 700px;
    }
}

@media (min-width: 1400px) {
    .payment .splitter, .payment h1 {
        padding-left: 10% !important;
        padding-right: 10% !important;
    }
    
    .payment .chosen-formula {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
}